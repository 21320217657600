import { HubConnectionState } from '@microsoft/signalr';
import { konsole } from '@/utils/konsole';
import {
  SCORING_WORKER_ACTION,
  SCORING_WORKER_HOST_ACTION,
  ScoringWorkerHostMsg,
} from '@/workers/scoring/types';
import { isChecklistCrucialAction } from '@/utils/checklist';
import {
  ScoringReducerAction,
  ScoringState,
  SCORING_REDUCER_ACTION,
} from './types';

export const scoringDefaultState = (
  initial?: Partial<ScoringState>,
): ScoringState => ({
  isOnline: true,
  wsConnection: HubConnectionState.Disconnected,
  worker: null,
  isWorkerReady: false,
  isScorerConnected: false,
  fixtureSummary: null,
  fixtureActions: null,
  newFixtureActions: [],
  checklistActions: [],
  fixtureId: '',
  fixtureChecklist: null,
  postMatchQa: null,
  deletedActionIds: [],
  supervisorCall: null,
  externalBasketStats: null,
  ...initial,
});

const SCORING_WORKER_HOST_ACTIONS = Object.values(SCORING_WORKER_HOST_ACTION);

export const scoringReducer = (
  state: ScoringState,
  dispatchedAction: ScoringReducerAction,
): ScoringState => {
  const { action, payload } = dispatchedAction;
  konsole.log('reducer>dispatch', action, payload);

  // Init action which passes a reference to worker
  if (action === SCORING_REDUCER_ACTION.WORKER_INIT) {
    return { ...state, worker: payload.worker };
  }
  if (action === SCORING_REDUCER_ACTION.NETWORK) {
    return { ...state, ...payload };
  }
  if (action === SCORING_WORKER_ACTION.WORKER_READY) {
    return { ...state, isWorkerReady: payload.isWorkerReady };
  }
  if (action === SCORING_REDUCER_ACTION.NEW_ACTION_SEEN) {
    const newFixtureActions = [...state.newFixtureActions];
    const seenActionIndex = newFixtureActions.findIndex(
      (action) => action.id === payload,
    );
    if (seenActionIndex < 0) return state;
    newFixtureActions.splice(seenActionIndex, 1);
    return {
      ...state,
      newFixtureActions,
    };
  }

  if (action === SCORING_WORKER_HOST_ACTION.FIXTURE_ID) {
    state.fixtureId = payload.fixtureId;
  }
  // Dispatched an action to be handled by worker
  // Should only post message to worker and not perform any state change
  // Any exceptions should be handled above or in a custom reducer only actions
  if (
    state.worker &&
    SCORING_WORKER_HOST_ACTIONS.includes(action as SCORING_WORKER_HOST_ACTION)
  ) {
    state.worker.postMessage({
      action,
      payload,
    } as ScoringWorkerHostMsg);
    return state;
  }

  // Worker posted message which dispatched below action
  switch (action) {
    case SCORING_WORKER_ACTION.WS_CONNECTION:
      return { ...state, wsConnection: payload.wsConnection };
    case SCORING_WORKER_ACTION.SUMMARY_RECEIVED:
      return { ...state, ...payload };
    case SCORING_WORKER_ACTION.ACTION_ADDED:
      const { fixtureActions } = state;
      if (fixtureActions !== null) {
        fixtureActions.actions = [payload, ...fixtureActions.actions];
      }
      return {
        ...state,
        fixtureActions,
        newFixtureActions: [...state.newFixtureActions, payload],
        checklistActions: isChecklistCrucialAction(payload)
          ? [...state.checklistActions, payload]
          : state.checklistActions,
      };
    case SCORING_WORKER_ACTION.ACTIONS_RECEIVED:
      return { ...state, ...payload };
    case SCORING_WORKER_ACTION.CHECKLIST_RECEIVED:
      return { ...state, fixtureChecklist: payload };

    case SCORING_WORKER_ACTION.STATISTICS_ADDED:
      return { ...state, externalBasketStats: payload };

    case SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_ADDED:
      if (!state.fixtureChecklist) {
        return state;
      }
      const addFixtureChecklist = [...state.fixtureChecklist];
      const itemToCheck = addFixtureChecklist.find(
        (item) => item.elementId === payload.elementId,
      );
      if (!itemToCheck) {
        return state;
      }

      itemToCheck.isChecked = true;

      return {
        ...state,
        fixtureChecklist: addFixtureChecklist,
      };
    case SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_REMOVED:
      if (!state.fixtureChecklist) {
        return state;
      }
      const removeFixtureChecklist = [...state.fixtureChecklist];
      const itemToUncheck = removeFixtureChecklist.find(
        (item) => item.elementId === payload.elementId,
      );

      if (!itemToUncheck) {
        return state;
      }

      itemToUncheck.isChecked = false;

      return {
        ...state,
        fixtureChecklist: removeFixtureChecklist,
      };
    case SCORING_WORKER_ACTION.ACTION_UPDATED:
      if (!state.fixtureActions) return state;
      const updatedActionIndex = state.fixtureActions.actions.findIndex(
        ({ id }) => id === payload.id,
      );

      if (updatedActionIndex < 0) return state;
      const actionsWithUpdatedAction = [...state.fixtureActions.actions];
      actionsWithUpdatedAction[updatedActionIndex] = {
        ...actionsWithUpdatedAction[updatedActionIndex],
        ...payload,
        slaBreachSummary:
          actionsWithUpdatedAction[updatedActionIndex].slaBreachSummary,
        isSlaBreachApplicable:
          actionsWithUpdatedAction[updatedActionIndex].isSlaBreachApplicable,
      };

      return {
        ...state,
        fixtureActions: {
          fixtureId: state.fixtureId,
          actions: [...actionsWithUpdatedAction],
        },
      };

    case SCORING_WORKER_ACTION.SLA_BREACH_UPDATED:
      if (!state.fixtureActions) {
        return state;
      }
      const actionToUpdate = state.fixtureActions.actions.find(
        ({ id }) => id === payload.id,
      );

      if (!actionToUpdate) return state;

      const updatedAction = {
        ...actionToUpdate,
        slaBreachSummary: {
          ...payload,
        },
      };

      const actionsWithUpdate = state.fixtureActions?.actions.map((action) =>
        action.id === payload.id ? updatedAction : action,
      );

      return {
        ...state,
        fixtureActions: {
          actions: actionsWithUpdate,
        },
      };

    case SCORING_WORKER_ACTION.SLA_BREACH_DELETED:
      if (!state.fixtureActions) {
        return state;
      }
      const actionToDeleteLatency = state.fixtureActions.actions.find(
        ({ id }) => id === payload.id,
      );

      if (!actionToDeleteLatency) return state;

      const deletedLatencyAction = {
        ...actionToDeleteLatency,
        slaBreachSummary: null,
      };

      const actionsWithDeletedLatency = state.fixtureActions?.actions.map(
        (action) => (action.id === payload.id ? deletedLatencyAction : action),
      );

      return {
        ...state,
        fixtureActions: {
          actions: actionsWithDeletedLatency,
        },
      };

    case SCORING_WORKER_ACTION.CONNECTION_STATUS_RECEIVED:
      return {
        ...state,
        isScorerConnected: payload.fixtureConnectionStatus.isConnected,
      };

    case SCORING_WORKER_ACTION.REQUEST_CONFIRMATION:
      return state;

    case SCORING_WORKER_ACTION.POST_MATCH_QA_REFRESHED:
      return {
        ...state,
        postMatchQa: payload,
      };
    case SCORING_WORKER_ACTION.SUPERVISOR_CALL_REQUESTED:
      return {
        ...state,
        supervisorCall: payload,
      };

    default:
      konsole.warn('Wrong action type :(', action, payload);
      return state;
  }
};
