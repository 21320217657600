import { FC, useContext, useMemo, useReducer } from 'react';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { PreferencesContext } from '@/contexts/preferences/context';
import {
  GenericFilterValue,
  RADIO_COMMENT_LABELS,
  RADIO_FLAG_LABELS,
  RADIO_PLAYER_LABELS,
  RADIO_ACTIONS_UPDATED,
  RADIO_SLA_MISTAKE,
  FILTER_DISPLAY_NAME,
  RADIO_SLA_LATENCY,
} from '../constants';
import { GenericCheckboxFilterGroup } from '../GenericCheckboxFilterGroup';
import { FilterTemplateTeam, FilterTemplateSendType } from '../types';
import { GenericFilterRadios } from '../GenericFilterRadios';
import { isUniqueTemplateName } from '../utils';
import { ActionstFiltersTemplateActions } from './ActionFiltersTemplateActions';
import {
  ActionFiterType,
  HideableFilterItem,
  MTEmojiType,
  PlayerFilterValue,
  TemplateFormData,
} from './types';
import { NEW_TEMPLATE_ID } from './DisplayTemplates';
import { EmojiInput } from './Emoji/EmojiInput';

const TEST_ID = {
  TEMPLATE_ICON: 'template-icon',
  SEND_TYPE: 'send-type',
  TEAM: 'team',
  COMMENT: 'comment',
  PLAYER: 'player',
  FLAG: 'flag',
  ACTIONS_UPDATED: 'actions-updated',
  SLA_LATENCY: 'latency',
  MISTAKE: 'mistake',
};

export const TEMPLATE_NAME_PLACEHOLDER = 'Template Name*';

type ActionFiltersTemplateFormProps = {
  template: TemplateFormData;
  onSave: (template: TemplateFormData) => void;
  onCancel: VoidFunction;
  editFromDrawer?: boolean;
  sportId: number;
};

type TemplateFormInnerData = {
  emoji: MTEmojiType | null;
  templateName: string;
  actions: HideableFilterItem<ActionFiterType>;
  team: string[];
  sendTypes: HideableFilterItem<string>;
  player: PlayerFilterValue;
  comment: GenericFilterValue;
  flag: GenericFilterValue;
  actionsUpdated: GenericFilterValue;
  latency: GenericFilterValue;
  mistake: GenericFilterValue;
};

export const ActionFiltersTemplateForm: FC<ActionFiltersTemplateFormProps> = ({
  template,
  onSave,
  onCancel,
  editFromDrawer,
  sportId,
}) => {
  const { sendTypes: sendTypesOptions, templates } =
    useContext(PreferencesContext);

  const [templateData, updateTemplateData] = useReducer<
    (
      state: TemplateFormInnerData,
      update: Partial<TemplateFormInnerData>,
    ) => TemplateFormInnerData
  >(
    (state, update) => ({
      ...state,
      ...update,
    }),
    {
      emoji: template.emoji ?? null,
      templateName: template?.templateName ?? '',
      actions: template?.actions ?? { values: [], hidden: false },
      team: template?.team ?? [],
      player: template?.player ?? '',
      sendTypes: template?.sendTypes ?? { values: [], hidden: false },
      comment: template?.comment ?? '',
      flag: template?.flag ?? '',
      actionsUpdated: template?.actionsUpdated ?? 'falsy',
      latency: template.latency ?? '',
      mistake: template.mistake ?? '',
    },
  );

  const {
    emoji,
    templateName,
    actions,
    team,
    player,
    sendTypes,
    comment,
    flag,
    actionsUpdated,
    latency,
    mistake,
  } = templateData;

  const isUniqueName = useMemo(
    () => isUniqueTemplateName(templates, templateName, template.id, sportId),
    [templates, templateName, template.id, sportId],
  );

  const isSaveEnabled = () =>
    templateName &&
    (!!actions.values.length ||
      !!team.length ||
      !!sendTypes.values.length ||
      player ||
      comment ||
      flag ||
      latency ||
      mistake ||
      actionsUpdated === 'truthy') &&
    isUniqueName;

  const composeTemplate = (): TemplateFormData => {
    return {
      id: template?.id === NEW_TEMPLATE_ID ? uuid() : template.id,
      sportId: sportId,
      templateName: templateName ? templateName : undefined,
      emoji: emoji ?? undefined,
      actions: !!actions.values.length ? actions : undefined,
      team: !!team.length ? (team as FilterTemplateTeam[]) : undefined,
      sendTypes: !!sendTypes.values.length
        ? (sendTypes as HideableFilterItem<FilterTemplateSendType>)
        : undefined,
      player: player ? player : undefined,
      comment: comment ? comment : undefined,
      flag: flag ? flag : undefined,
      actionsUpdated: actionsUpdated ? actionsUpdated : undefined,
      latency: latency ? latency : undefined,
      mistake: mistake ? mistake : undefined,
    };
  };

  const titleError = !templateName.length || !isUniqueName;

  const titleHelperText = !templateName.length
    ? 'Field is required'
    : !isUniqueName
    ? `There is already template with name '${templateName}'`
    : undefined;

  const filters = (
    <>
      <Stack direction={'row'} gap={2}>
        <EmojiInput
          value={emoji}
          onChange={(value) =>
            updateTemplateData({ emoji: value ?? undefined })
          }
          data-testid={TEST_ID.TEMPLATE_ICON}
        />
        <TextField
          placeholder={TEMPLATE_NAME_PLACEHOLDER}
          variant='outlined'
          size='small'
          value={templateName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateTemplateData({ templateName: event.target.value });
          }}
          sx={{ mb: 2 }}
          fullWidth
          required
          error={titleError}
          helperText={titleHelperText}
          aria-label={ARIA_LABEL.TEMPLATE_NAME}
        />
      </Stack>

      <ActionstFiltersTemplateActions
        selectedTypes={actions}
        setSelectedTypes={(value) => updateTemplateData({ actions: value })}
        editFromDrawer={editFromDrawer}
        sportId={template?.sportId}
      />
      <Divider sx={{ my: 2 }} />
      <GenericCheckboxFilterGroup
        key={TEST_ID.SEND_TYPE}
        label={COMMON_STRING.SEND_TYPE}
        options={sendTypesOptions?.map((option) => option.name) ?? []}
        selected={sendTypes.values}
        hidden={sendTypes.hidden}
        onSelect={(values: string[]) => {
          updateTemplateData({
            sendTypes: { values: values, hidden: sendTypes.hidden },
          });
        }}
        onHide={(hidden: boolean) => {
          updateTemplateData({
            sendTypes: { values: sendTypes.values, hidden: hidden },
          });
        }}
        name={TEST_ID.SEND_TYPE}
        hideable
        data-testid={TEST_ID.SEND_TYPE}
      />
      <Divider sx={{ my: 2 }} />
      <GenericCheckboxFilterGroup
        key={TEST_ID.TEAM}
        label={COMMON_STRING.TEAM}
        options={Object.values(FilterTemplateTeam)}
        selected={team}
        onSelect={(values: string[]) => updateTemplateData({ team: values })}
        name={TEST_ID.TEAM}
        data-testid={TEST_ID.TEAM}
      />
      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.PLAYER}>
        <Typography sx={{ fontSize: 12 }}>{COMMON_STRING.PLAYER}</Typography>
        <GenericFilterRadios
          value={player}
          onValueChange={(values: PlayerFilterValue) =>
            updateTemplateData({ player: values })
          }
          labels={RADIO_PLAYER_LABELS}
          name={TEST_ID.PLAYER}
          row
        />
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.COMMENT}>
        <Typography sx={{ fontSize: 12 }}>{COMMON_STRING.COMMENT}</Typography>
        <GenericFilterRadios
          value={comment}
          onValueChange={(values: GenericFilterValue) =>
            updateTemplateData({ comment: values })
          }
          labels={RADIO_COMMENT_LABELS}
          name={TEST_ID.COMMENT}
          row
        />
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.FLAG}>
        <Typography sx={{ fontSize: 12 }}>
          {FILTER_DISPLAY_NAME.FLAG}
        </Typography>
        <GenericFilterRadios
          value={flag}
          onValueChange={(values: GenericFilterValue) =>
            updateTemplateData({ flag: values })
          }
          labels={RADIO_FLAG_LABELS}
          name={TEST_ID.FLAG}
          row
        />
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.ACTIONS_UPDATED}>
        <Typography sx={{ fontSize: 12 }}>{COMMON_STRING.ACTIONS}</Typography>
        <GenericFilterRadios
          value={actionsUpdated}
          onValueChange={(values: GenericFilterValue) =>
            updateTemplateData({ actionsUpdated: values })
          }
          labels={RADIO_ACTIONS_UPDATED}
          name='showStrikeThroughActions'
          row
        />
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.SLA_LATENCY}>
        <Typography sx={{ fontSize: 12 }}>{COMMON_STRING.LATENCY}</Typography>
        <GenericFilterRadios
          value={latency}
          onValueChange={(values: GenericFilterValue) =>
            updateTemplateData({ latency: values })
          }
          labels={RADIO_SLA_LATENCY}
          name={TEST_ID.SLA_LATENCY}
          row
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack px={2} sx={{ p: 0 }} data-testid={TEST_ID.MISTAKE}>
        <Typography sx={{ fontSize: 12 }}>{COMMON_STRING.MISTAKE}</Typography>
        <GenericFilterRadios
          value={mistake}
          onValueChange={(values: GenericFilterValue) =>
            updateTemplateData({ mistake: values })
          }
          labels={RADIO_SLA_MISTAKE}
          name={TEST_ID.MISTAKE}
          row
        />
      </Stack>
    </>
  );

  return (
    <Stack
      height='100%'
      alignItems='stretch'
      justifyContent='space-between'
      divider={<Divider flexItem />}
    >
      <Stack
        py={2}
        gap={2}
        flex={1}
        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        divider={<Divider flexItem />}
      >
        <Stack sx={{ p: 2 }}>{filters}</Stack>
        {!editFromDrawer && (
          <Stack px={2} direction='row' justifyContent='space-between'>
            <Button
              variant='text'
              color='primary'
              sx={{
                width: 100,
              }}
              onClick={onCancel}
            >
              {COMMON_STRING.CANCEL}
            </Button>
            <Button
              variant='contained'
              sx={{ width: 100 }}
              disabled={!isSaveEnabled()}
              onClick={() => onSave(composeTemplate())}
            >
              {COMMON_STRING.SAVE}
            </Button>
          </Stack>
        )}
      </Stack>
      {editFromDrawer && (
        <Stack p={2} gap={1} direction='row' justifyContent='space-between'>
          <Button variant='text' onClick={onCancel}>
            {COMMON_STRING.CANCEL}
          </Button>
          <Button
            variant='contained'
            type='submit'
            onClick={() => onSave(composeTemplate())}
            sx={{ flex: 1 }}
            disabled={!isSaveEnabled()}
          >
            {COMMON_STRING.APPLY}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
