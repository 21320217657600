import { PropsWithChildren, useContext, useEffect } from 'react';
import type { FixtureAction } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SCORING_WORKER_HOST_ACTION,
  UpdateActionFlagMsg,
} from '@/workers/scoring/types';
import { useSlaBreachActionEvaluation } from '@/service/hooks/useSlaBreachActionEvaluation';
import { UIStateContext } from '@/contexts/UIState/context';
import { UIState } from '@/contexts/UIState/types';
import {
  ActionFilter,
  useActionsFilters,
} from '../ActionFilters/useActionsFilters';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from './ActionsTableContext';

export interface FilterApiState {
  hasLatency: boolean | '';
  hasMistake: boolean | '';
}

export interface ActionsTableContextProviderProps extends PropsWithChildren {
  initialFilters?: ActionFilter[];
  actions?: FixtureAction[];
  excludeTemplates?: boolean;
}

export const ActionsTableProvider = ({
  initialFilters,
  actions,
  children,
  excludeTemplates,
}: ActionsTableContextProviderProps) => {
  const {
    state: { fixtureId },
    useDispatchManyWithResponse,
  } = useContext(ScoringContext);
  const { isSlaBreachActionSelected } = useContext<UIState>(UIStateContext);

  const { slaBreachEvaluation, mutate } = useSlaBreachActionEvaluation({
    fixtureId,
  });

  useEffect(() => {
    if (!actions) return;
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const actionsFilters = useActionsFilters({
    initialFilters,
    actions: isSlaBreachActionSelected ? slaBreachEvaluation : actions,
    excludeTemplates,
  });

  const dispatchFlagUpdate = useDispatchManyWithResponse<UpdateActionFlagMsg>(
    SCORING_WORKER_HOST_ACTION.UPDATE_ACTION_FLAG,
  );
  const providerValue: ActionsTableContextType = {
    ...actionsFilters,
    dispatchFlagUpdate,
    excludeTemplates,
  };

  return (
    <ActionsTableContext.Provider value={providerValue}>
      {children}
    </ActionsTableContext.Provider>
  );
};
