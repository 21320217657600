import { useContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  FixtureChecklistAddElementMsg,
  SCORING_WORKER_HOST_ACTION,
  FixtureChecklistRemoveElementMsg,
  AddPerfectScorerRatingMsg,
} from '@/workers/scoring/types';
import { ChecklistCheckboxState } from '@/contexts/checklist/types';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';
import { useScoringRate } from '@/service/hooks/useScoringRate';
import { SUCCESS_STRING } from '@/constants/dictionary';

export interface UseChecklistCheckboxParams {
  checklistCheckboxState: ChecklistCheckboxState;
}

export function useChecklistCheckbox({
  checklistCheckboxState: checkbox,
}: UseChecklistCheckboxParams) {
  const {
    state: { fixtureId, fixtureChecklist, fixtureSummary },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const { mutate } = useScoringRate({
    fixtureId,
  });

  const checklistElement = fixtureChecklist
    ? fixtureChecklist.find(({ index }) => index === checkbox.index)
    : null;

  const slaChecklistElement =
    fixtureChecklist &&
    fixtureChecklist.find(
      ({ index }) => index === CHECKLIST_ELEMENT_INDEX.SLA_BREACH_COMPLETE,
    );

  const addChecklistElementAction =
    useDispatchWithResponse<FixtureChecklistAddElementMsg>(
      SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_ADD,
    );

  const AddPerfectScorerRatingMsg =
    useDispatchWithResponse<AddPerfectScorerRatingMsg>(
      SCORING_WORKER_HOST_ACTION.ADD_PERFECT_SCORER_RATING,
    );

  const check = async () => {
    if (!checklistElement) return;
    addChecklistElementAction.dispatch({
      fixtureId,
      elementId: checklistElement.elementId,
    });

    if (
      fixtureSummary?.collectionId &&
      slaChecklistElement &&
      checklistElement.index === CHECKLIST_ELEMENT_INDEX.ALL_MATCH_DATA_CORRECT
    ) {
      AddPerfectScorerRatingMsg.dispatch({
        fixtureId,
        collectionId: fixtureSummary?.collectionId,
        requestTime: new Date().toISOString(),
      })
        .then(() => {
          addChecklistElementAction.dispatch({
            fixtureId,
            elementId: slaChecklistElement.elementId,
          });
        })
        .then(() => {
          mutate();
        })
        .finally(() => {
          enqueueSnackbar(SUCCESS_STRING.SUCCESS_PERFECT_RATING, {
            variant: 'success',
          });
        });
    }
  };

  const removeChecklistElementAction =
    useDispatchWithResponse<FixtureChecklistRemoveElementMsg>(
      SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_REMOVE,
    );

  const uncheck = () => {
    if (!checklistElement) return;
    removeChecklistElementAction.dispatch({
      fixtureId,
      elementId: checklistElement.elementId,
    });
  };

  const isLoading =
    addChecklistElementAction.isLoading ||
    removeChecklistElementAction.isLoading;

  return {
    check,
    uncheck,
    isLoading,
  };
}
