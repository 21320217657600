import { HubConnectionState } from '@microsoft/signalr';
import {
  Actions,
  ActionType,
  FixtureAction,
  FixtureActionFlag,
  FixtureConnectionStatus,
  FixtureSummary,
  FixturePostMatchCheck,
  MatchSummaryTeam,
  Player,
  SocketRequestConfirmation,
  StatusReason,
  FixtureSupervisorCall,
  SocketLatencyEvaluation,
  DeleteLatencyEvaluation,
  ScorerRatingsWithData,
  ScorerRatingBase,
  FixtureBasketStats,
  SocketSlaBreachUpdate,
  SocketSlaBreachDeleted,
} from '@/service/types';
import { FixtureChecklistElement } from '@/service/types/checklist';
import {
  CollectionStatusId,
  CoverageLevelId,
  FixtureStatusId,
} from '@/service/constants';
import { ScoringState } from '@/contexts/scoring/types';

// Messages from worker to host
/**
 * This enum contains action/message names dispatched by WebWorker.
 * Those messages are handled by main thread (worker host).
 * Most of them directly pass the corresponding data coming from WebSocket.
 */
export enum SCORING_WORKER_ACTION {
  WORKER_READY = 'workerReady',
  WS_CONNECTION = 'wsConnection',
  LOG = 'log',
  REQUEST_CONFIRMATION = 'requestConfirmationReceived',
  ACTIONS_RECEIVED = 'actionsReceived',
  ACTION_ADDED = 'actionAdded',
  ACTION_UPDATED = 'actionUpdated',
  SUMMARY_RECEIVED = 'summaryReceived',
  SLA_BREACH_UPDATED = 'slaBreachUpdated',
  SLA_BREACH_DELETED = 'slaBreachDeleted',
  CONNECTION_STATUS_RECEIVED = 'connectionStatusReceived',
  CHECKLIST_RECEIVED = 'checklistReceived',
  CHECKLIST_ELEMENT_ADDED = 'checklistAdded',
  CHECKLIST_ELEMENT_REMOVED = 'checklistRemoved',
  POST_MATCH_QA_REFRESHED = 'postMatchQaRefreshed',
  SUPERVISOR_CALL_REQUESTED = 'supervisorCallRequested',
  STATISTICS_ADDED = 'statisticsAdded',
}
export const LoggerStatus = {
  ok: 'ok',
  debug: 'debug',
  info: 'info',
  notice: 'notice',
  warn: 'warn',
  error: 'error',
  critical: 'critical',
  alert: 'alert',
  emerg: 'emerg',
} as const;
export type LoggerStatusType = (typeof LoggerStatus)[keyof typeof LoggerStatus];

export type WorkerReadyMsg = {
  action: SCORING_WORKER_ACTION.WORKER_READY;
  payload: { isWorkerReady: boolean };
};
export type WebSocketConnectionMsg = {
  action: SCORING_WORKER_ACTION.WS_CONNECTION;
  payload: { wsConnection: HubConnectionState };
};
export type LoggerMsg = {
  action: SCORING_WORKER_ACTION.LOG;
  payload: {
    level: LoggerStatusType | undefined;
    message: string;
    messageContext?: object;
  };
};
export type FixtureSummaryMsg = {
  action: SCORING_WORKER_ACTION.SUMMARY_RECEIVED;
  payload: { fixtureSummary: FixtureSummary };
};
export type FixtureConnectionStatusMsg = {
  action: SCORING_WORKER_ACTION.CONNECTION_STATUS_RECEIVED;
  payload: { fixtureConnectionStatus: FixtureConnectionStatus };
};
export type RequestConfirmationMsg = {
  action: SCORING_WORKER_ACTION.REQUEST_CONFIRMATION;
  payload: SocketRequestConfirmation;
};
export type FixtureActionsMsg = {
  action: SCORING_WORKER_ACTION.ACTIONS_RECEIVED;
  payload: { fixtureActions: Actions; checklistActions: FixtureAction[] };
};
export type NewFixtureActionMsg = {
  action: SCORING_WORKER_ACTION.ACTION_ADDED;
  payload: FixtureAction;
};
export type ChecklistReceivedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_RECEIVED;
  payload: FixtureChecklistElement[];
};
export type ChecklistElementAddedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_ADDED;
  payload: CheckboxActionResponse;
};

export type ChecklistElementRemovedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_REMOVED;
  payload: CheckboxActionResponse;
};

export type FixtureActionUpdatedActionMsg = {
  action: SCORING_WORKER_ACTION.ACTION_UPDATED;
  payload: FixtureAction;
};

export type SlaBreachUpdateMsg = {
  action: SCORING_WORKER_ACTION.SLA_BREACH_UPDATED;
  payload: SocketSlaBreachUpdate;
};

export type SlaBreachDeletedMsg = {
  action: SCORING_WORKER_ACTION.SLA_BREACH_DELETED;
  payload: SocketSlaBreachDeleted;
};

export type PostMatchQaRefreshedActionMsg = {
  action: SCORING_WORKER_ACTION.POST_MATCH_QA_REFRESHED;
  payload: FixturePostMatchCheck;
};

export type SupervisorCallRequestedActionMsg = {
  action: SCORING_WORKER_ACTION.SUPERVISOR_CALL_REQUESTED;
  payload: FixtureSupervisorCall;
};

export type ExternalBasketballStatisticsAddedMsg = {
  action: SCORING_WORKER_ACTION.STATISTICS_ADDED;
  payload: FixtureBasketStats;
};

export type ScoringWorkerMsg =
  | WorkerReadyMsg
  | WebSocketConnectionMsg
  | LoggerMsg
  | FixtureSummaryMsg
  | FixtureConnectionStatusMsg
  | FixtureActionsMsg
  | SlaBreachUpdateMsg
  | SlaBreachDeletedMsg
  | NewFixtureActionMsg
  | RequestConfirmationMsg
  | ChecklistReceivedActionMsg
  | ChecklistElementAddedActionMsg
  | ChecklistElementRemovedActionMsg
  | FixtureActionUpdatedActionMsg
  | PostMatchQaRefreshedActionMsg
  | SupervisorCallRequestedActionMsg
  | ExternalBasketballStatisticsAddedMsg;

export type ScoringWorkerMsgEvent = MessageEvent<ScoringWorkerMsg>;
// END: Messages from worker to host
// Messages from host to worker
/**
 * This enum contains action/message names dispatched by host.
 * Those messages are received by WebWorker and most of them
 * directly invoke a WebSocket action/message.
 */
export enum SCORING_WORKER_HOST_ACTION {
  LOG_ENABLE = 'log',
  KILL = 'kill',
  TOKEN = 'token',
  /**
   * An unified action which initially invokes many `fixtureId` related socket methods.
   */
  FIXTURE_ID = 'fixtureId',
  /**
   * Subscription methods
   */
  ACTIONS_SUBSCRIBE = 'subscribeActions',
  ACTIONS_UNSUBSCRIBE = 'unsubscribeActions',
  POST_MATCH_QA_SUBSCRIBE = 'subscribePostMatchQa',
  POST_MATCH_QA_UNSUBSCRIBE = 'unsubscribePostMatchQa',
  SUMMARY_SUBSCRIBE = 'subscribeSummary',
  SUMMARY_UNSUBSCRIBE = 'unsubscribeSummary',
  SUPERVISOR_CALL_SUBSCRIBE = 'subscribeSupervisorCall',
  SUPERVISOR_CALL_UNSUBSCRIBE = 'unsubscribeSupervisorCall',
  CONNECTION_STATUS_SUBSCRIBE = 'subscribeConnectionStatus',
  CONNECTION_STATUS_UNSUBSCRIBE = 'unsubscribeConnectionStatus',
  CHECKLIST_SUBSCRIBE = 'subscribeChecklist',
  CHECKLIST_UNSUBSCRIBE = 'unsubscribeChecklist',

  /**
   * Particural methods
   */
  CHECKLIST_ELEMENT_ADD = 'addChecklistElement',
  CHECKLIST_ELEMENT_REMOVE = 'removeChecklistElement',
  ACTION_COMMENT_ADD = 'addActionComment',
  ACTION_COMMENT_UPDATE = 'updateActionComment',
  ACTION_COMMENT_REMOVE = 'removeActionComment',
  POST_MATCH_CHECK_COMPLETE_SET = 'setPostMatchCheck',
  POST_MATCH_CHECK_COMPLETE_UNSET = 'unsetPostMatchCheck',
  SLA_BREACH_COMPLETE_SET = 'setQaSlaFinished',
  SLA_BREACH_COMPLETE_UNSET = 'unSetQaSlaFinished',
  FIXTURE_STATUSES_SET = 'setFixtureStatuses',
  UPDATE_POST_MATCH_QA = 'updatePostMatchQa',
  UPDATE_ACTION_FLAG = 'updateActionFlag',
  UPDATE_FIXTURE_ACTION = 'updateFixtureAction',
  ADD_FIXTURE_ACTION = 'addFixtureAction',
  DELETE_FIXTURE_ACTION = 'deleteFixtureAction',
  RESTORE_FIXTURE_ACTION = 'restoreFixtureAction',
  ACKNOWLEDGE_SUPERVISOR_CALL = 'acknowledgeSupervisorCall',
  SET_SLA_BREACH_CHECKLIST = 'setQaSlaChecklistElement',
  SET_ACTION_LATENCY_EVALUATION = 'setActionLatencyEvaluation',
  DELETE_LATENCY_EVALUATION = 'deleteActionLatencyEvaluation',
  ADD_SCORER_RATING = 'addScorerRating',
  UPDATE_SCORER_RATING = 'updateScorerRating',
  REMOVE_SCORER_RATING = 'removeScorerRating',
  ADD_PERFECT_SCORER_RATING = 'addPerfectScorerRatings',
}

export interface PayloadBase {
  fixtureId: string;
}
export interface RequestConfirmationPayload extends PayloadBase {
  requestId: string;
}

export type LogEnableMessage = {
  action: SCORING_WORKER_HOST_ACTION.LOG_ENABLE;
  payload: boolean;
};
export type KillMsg = {
  action: SCORING_WORKER_HOST_ACTION.KILL;
  payload: null;
};
export type TokenMsg = {
  action: SCORING_WORKER_HOST_ACTION.TOKEN;
  payload: string;
};
export type FixtureIdMsg = {
  action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID;
  payload: PayloadBase & { oldFixtureId?: string };
};
export type FixturePostMatchQaSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_QA_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_QA_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureSupervisorCallSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.SUPERVISOR_CALL_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.SUPERVISOR_CALL_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureSummarySubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.SUMMARY_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.SUMMARY_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureActionsSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.ACTIONS_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureConnectionStatusSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.CONNECTION_STATUS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.CONNECTION_STATUS_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureChecklistSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.CHECKLIST_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.CHECKLIST_UNSUBSCRIBE;
  payload: PayloadBase;
};

export type FixtureChecklistAddElementMsg = {
  action: SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_ADD;
  payload: RequestConfirmationPayload & {
    elementId: FixtureChecklistElement['elementId'];
  };
};
export type FixtureChecklistRemoveElementMsg = {
  action: SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_REMOVE;
  payload: RequestConfirmationPayload & {
    elementId: FixtureChecklistElement['elementId'];
  };
};

export type PostMatchQaUpdateMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_POST_MATCH_QA;
  payload: RequestConfirmationPayload & {
    fixtureId: string;
  };
};

export type AcknowledgeSupervisorCallMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACKNOWLEDGE_SUPERVISOR_CALL;
  payload: RequestConfirmationPayload & {
    fixtureId: string;
    scorerId: string;
  };
};

export type ActionCommentAddMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_ADD;
  payload: RequestConfirmationPayload & {
    fixtureActionId: FixtureAction['id'];
    comment: string;
  };
};

export type ActionCommentUpdateMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_UPDATE;
  payload: RequestConfirmationPayload & {
    id: string;
    comment: string;
  };
};

export type ActionCommentRemoveMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_REMOVE;
  payload: RequestConfirmationPayload & {
    id: string;
  };
};

export type DeleteFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.DELETE_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureId: ScoringState['fixtureId'];
    fixtureActionId: FixtureAction['actionId'];
  };
};

export type RestoreFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.RESTORE_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureId: ScoringState['fixtureId'];
    fixtureActionId: FixtureAction['actionId'];
  };
};

export type UpdateFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureActionId: FixtureAction['id'];
    fixtureId: ScoringState['fixtureId'];
    newFixtureActionTypeId?: ActionType['id'];
    newFixtureActionSubtypeId?: ActionType['id'];
    newTeamId: MatchSummaryTeam['id'] | null;
    newPlayerId?: Player['id'];
  };
};

export type SetPostMatchCheckCompleteMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_SET
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_UNSET;
  payload: RequestConfirmationPayload;
};

export type SetSlaBreachCompleteMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.SLA_BREACH_COMPLETE_SET
    | SCORING_WORKER_HOST_ACTION.SLA_BREACH_COMPLETE_UNSET;
  payload: RequestConfirmationPayload & {
    timestamp: string;
  };
};

export type SetFixtureStatusMsg = {
  action: SCORING_WORKER_HOST_ACTION.FIXTURE_STATUSES_SET;
  payload: RequestConfirmationPayload & {
    fixtureStatus: FixtureStatusId;
    fixtureStatusReason?: StatusReason['reason'];
    fixtureStatusReasonCode?: StatusReason['code'];
    collectionStatus: CollectionStatusId;
    collectionStatusReason?: StatusReason['reason'];
    collectionStatusReasonCode?: StatusReason['code'];
    coverageLevel: CoverageLevelId;
    coverageLevelReason?: StatusReason['reason'];
    coverageLevelReasonCode?: StatusReason['code'];
  };
};

export type UpdateActionFlagMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_ACTION_FLAG;
  payload: RequestConfirmationPayload & FixtureActionFlag;
};

export type AddFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureActionTypeId: FixtureAction['fixtureActionTypeId'];
    fixtureActionSubTypeId?: FixtureAction['fixtureActionSubTypeId'];
    withLastFixtureActionParams: boolean;
    teamId?: FixtureAction['teamId'];
    playerId?: FixtureAction['playerId'];
  };
};

export type SetChecklistElementActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.SET_SLA_BREACH_CHECKLIST;
  payload: RequestConfirmationPayload & {
    ruleId: number;
    isBreached: boolean;
    timestamp: string;
  };
};

export type SetActionLatencyEvaluationMsg = {
  action: SCORING_WORKER_HOST_ACTION.SET_ACTION_LATENCY_EVALUATION;
  payload: RequestConfirmationPayload & SocketLatencyEvaluation;
};

export type DeleteActionLatencyEvaluation = {
  action: SCORING_WORKER_HOST_ACTION.DELETE_LATENCY_EVALUATION;
  payload: RequestConfirmationPayload & DeleteLatencyEvaluation;
};

export type AddScorerRatingMsg = {
  action: SCORING_WORKER_HOST_ACTION.ADD_SCORER_RATING;
  payload: RequestConfirmationPayload & ScorerRatingsWithData;
};

export type UpdateScorerRatingMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_SCORER_RATING;
  payload: RequestConfirmationPayload & ScorerRatingsWithData;
};

export type RemoveScorerRatingMsg = {
  action: SCORING_WORKER_HOST_ACTION.REMOVE_SCORER_RATING;
  payload: RequestConfirmationPayload & ScorerRatingBase;
};

export type AddPerfectScorerRatingMsg = {
  action: SCORING_WORKER_HOST_ACTION.ADD_PERFECT_SCORER_RATING;
  payload: RequestConfirmationPayload & {
    collectionId: string;
    requestTime: string;
  };
};

export type ScoringWorkerHostMsg =
  | LogEnableMessage
  | KillMsg
  | TokenMsg
  | FixtureIdMsg
  | FixturePostMatchQaSubscriptionMsg
  | FixtureSupervisorCallSubscriptionMsg
  | FixtureSummarySubscriptionMsg
  | FixtureConnectionStatusSubscriptionMsg
  | FixtureActionsSubscriptionMsg
  | FixtureChecklistSubscriptionMsg
  | FixtureChecklistAddElementMsg
  | FixtureChecklistRemoveElementMsg
  | ActionCommentAddMsg
  | ActionCommentUpdateMsg
  | PostMatchQaUpdateMsg
  | ActionCommentRemoveMsg
  | SetPostMatchCheckCompleteMsg
  | SetSlaBreachCompleteMsg
  | SetFixtureStatusMsg
  | UpdateActionFlagMsg
  | UpdateFixtureActionMsg
  | AddFixtureActionMsg
  | DeleteFixtureActionMsg
  | RestoreFixtureActionMsg
  | AcknowledgeSupervisorCallMsg
  | SetChecklistElementActionMsg
  | SetActionLatencyEvaluationMsg
  | DeleteActionLatencyEvaluation
  | AddScorerRatingMsg
  | UpdateScorerRatingMsg
  | RemoveScorerRatingMsg
  | AddPerfectScorerRatingMsg;

export type ScoringWorkerHostEvent = MessageEvent<ScoringWorkerHostMsg>;
// END: Messages from host to worker

// Utility types to grab particular action type properties
export type ActionOf<T> = T extends ScoringWorkerHostMsg ? T['action'] : never;
export type PayloadOf<T> = T extends ScoringWorkerHostMsg
  ? T['payload']
  : never;

// interface for main thread instance
export interface ScoringAPIWorker
  extends Omit<
    Worker,
    'postMessage' | 'addEventListener' | 'removeEventListener'
  > {
  addEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | EventListenerOptions,
  ): void;

  postMessage(message: ScoringWorkerHostMsg, transfer: Transferable[]): void;
  postMessage(
    message: ScoringWorkerHostMsg,
    options?: StructuredSerializeOptions,
  ): void;
}

export interface CheckboxActionResponse {
  clientId: string | null;
  elementId: string;
  fixtureId: string;
  requestId: string;
}
