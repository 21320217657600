import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl, AuthState } from '@okta/okta-auth-js';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { ROUTE_PATHS } from '@/router/constants';
import envVariables from '@/envVariables';
import { addAccessTokenToApiHeaders } from '@/service/request';

export type AuthProviderProps = {
  oktaAuth: OktaAuth;
};

const okta = new OktaAuth({
  issuer: envVariables.oktaIssuer,
  clientId: envVariables.oktaClientId,
  redirectUri: `${window.location.origin}${ROUTE_PATHS.OKTA_LOGIN_CALLBACK}`,
  scopes: ['openid', 'groups', 'profile', 'offline_access'],
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const restoreLocation: RestoreOriginalUriFunction = useCallback(
    async (_: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffectOnce(() => {
    const handleAuthState = (state: AuthState) => {
      addAccessTokenToApiHeaders(state.accessToken);
    };

    okta.start();
    okta.authStateManager.subscribe(handleAuthState);

    return () => {
      okta.authStateManager.unsubscribe(handleAuthState);
      okta.stop();
    };
  });

  useEffect(() => {
    return () => {
      okta.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreLocation}>
      {children}
    </Security>
  );
};
