import { mdiAccountTieHatOutline } from '@mdi/js';
import { IconButton } from '@mui/material';
import { Icon } from '@mdi/react';
import { useContext } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';
import { BUTTON_NAME } from './constants';

export const EvaluatorCell = () => {
  const { permissions } = useContext(RolePermissionsContext);

  return (
    <CustomTooltip title={COMMON_STRING.POLICEMAN_TOOLTIP}>
      <span>
        <IconButton
          sx={{
            '&:hover': { color: (theme) => theme.palette.error.main },
          }}
          name={BUTTON_NAME.EVALUATION_SUPERVISOR}
          aria-label='evaluation-supervisor'
          disabled={
            !hasPermissionsToElement(
              'FixturePage.QA.EvaluationOfficer',
              permissions,
            )
          }
        >
          <Icon path={mdiAccountTieHatOutline} size={0.7} />
        </IconButton>
      </span>
    </CustomTooltip>
  );
};
