import { MutableRefObject, useContext } from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { FixtureAction } from '@/service/types';
import { UIStateContext } from '@/contexts/UIState/context';
import { ActionDialogProps, ActionDialogType } from './types';

export interface ActionDialogState {
  dialog?: ActionDialogType;
  props?: ActionDialogProps;
}

export const useActionDialog = () => {
  const {
    dialogWindow,
    setDialogWindow,
    setHotkeysDisabled,
    setShowRowEditPopup,
    setShowRowLatency,
  } = useContext(UIStateContext);

  const closeActionDialog = () => {
    setHotkeysDisabled(false);
    setDialogWindow({
      dialog: undefined,
      props: undefined,
    });
    setShowRowEditPopup(false);
    setShowRowLatency(false);
  };

  const openActionDialog = (
    dialog: ActionDialogType,
    action: FixtureAction,
    listRef?: MutableRefObject<ViewportListRef | null>,
    isScrollTopVisible?: boolean,
  ) => {
    setHotkeysDisabled(true);
    setDialogWindow({
      dialog,
      props: {
        onClose: closeActionDialog,
        action,
        listRef,
        isScrollTopVisible,
      },
    });
  };

  return {
    openActionDialog,
    actionDialog: dialogWindow,
    setActionDialog: setDialogWindow,
    closeActionDialog,
  };
};
