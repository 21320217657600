import { useContext, useMemo } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import envVariables from '@/envVariables';

const VOLLEYBALL_SPORT_NAMES = [
  'volleyball',
  'beach-volleyball',
  'snow-volleyball',
];

interface GenerateOpsUiUrlParams {
  sportName: string;
  seasonId: string;
  fixtureId: string;
  isVolleyball: boolean;
}
export function generateOpsUiUrl({
  sportName,
  seasonId,
  fixtureId,
  isVolleyball,
}: GenerateOpsUiUrlParams) {
  const pathPrefix = `/tournament/${isVolleyball ? 'volleyball' : sportName}`;
  return `${envVariables.opsUiUrl}${pathPrefix}/${seasonId}/${fixtureId}`;
}

interface UseOpsUiUrlParams {
  fixtureId: string;
}

export function useOpsUiUrl({ fixtureId }: UseOpsUiUrlParams) {
  const {
    fixtureConfigState: { fixtureConfig, error, isLoading, mutate },
  } = useContext(ScoringContext);

  const linkUrl = useMemo(() => {
    if (!fixtureConfig) return '';
    const season = fixtureConfig.fixture.stage.season;
    const sportName = season.competition.sport.name
      .toLowerCase()
      .replace(/\s+/g, '-');
    const url = generateOpsUiUrl({
      sportName,
      seasonId: season.id,
      fixtureId,
      isVolleyball: VOLLEYBALL_SPORT_NAMES.includes(sportName),
    });
    return url;
  }, [fixtureConfig, fixtureId]);

  return {
    data: linkUrl,
    error,
    isLoading,
    mutate,
  };
}
