import { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconProps,
  Stack,
  Typography,
} from '@mui/material';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { ChecklistCheckboxState } from '@/contexts/checklist/types';
import { COMMON_STRING, CONFIRMATION } from '@/constants/dictionary';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';
import { useChecklistCheckbox } from './useChecklistCheckbox';

export interface ChecklistCheckboxProps extends CheckboxProps {
  checklistCheckboxState: ChecklistCheckboxState;
  beforeChange?: (isChecked: boolean) => Promise<unknown> | unknown;
  onBeforeChangeError?: (error: Error) => void;
  hasWarnings?: boolean;
}

export const ChecklistCheckbox: FC<ChecklistCheckboxProps> = (props) => {
  const {
    checklistCheckboxState,
    hasWarnings = false,
    beforeChange,
    onBeforeChangeError,
    ...checkboxProps
  } = props;

  const {
    isLoading: isWaitingForChecklistChange,
    check,
    uncheck,
  } = useChecklistCheckbox({
    checklistCheckboxState,
  });
  const [isWaitingForBeforeChange, setIsWaitingForBeforeChange] =
    useState(false);

  const { isChecked, isReadyToCheck, requiresConfirmation } =
    checklistCheckboxState;

  const doChangeRef = useRef(function () {});
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [wasCheckedWhenDialogOpened, setWasCheckedWhenDialogOpened] =
    useState(isChecked);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);

  const openConfirmationDialog = () => {
    setWasCheckedWhenDialogOpened(isChecked);
    setConfirmationDialog(true);
  };
  const closeConfirmationDialog = () => {
    setConfirmationDialog(false);
    setConfirmCheckbox(false);
  };

  useEffect(() => {
    if (isChecked !== wasCheckedWhenDialogOpened) {
      closeConfirmationDialog();
    }
  }, [isChecked, wasCheckedWhenDialogOpened]);

  const doChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (beforeChange) {
      setIsWaitingForBeforeChange(true);
      try {
        await beforeChange(isChecked);
      } catch (e) {
        const error = new Error('Could not change', {
          cause: { error: e, checkbox: checklistCheckboxState },
        });
        if (onBeforeChangeError) return onBeforeChangeError(error);
        throw error;
      } finally {
        setIsWaitingForBeforeChange(false);
      }
    }
    if (isChecked) {
      uncheck();
    } else {
      await check();
    }
    checkboxProps.onChange && checkboxProps.onChange(event, checked);
    doChangeRef.current = function () {};
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (isLoading) return;
    if (requiresConfirmation) {
      doChangeRef.current = doChange.bind(event.target, event, checked);
      return openConfirmationDialog();
    }
    doChange(event, checked);
  };
  const handleconfirmCheckbox = () => {
    setConfirmCheckbox(!confirmCheckbox);
  };

  const isAllMatchCorrect =
    checklistCheckboxState.index ===
      CHECKLIST_ELEMENT_INDEX.ALL_MATCH_DATA_CORRECT && !isChecked;

  const isLoading = isWaitingForChecklistChange || isWaitingForBeforeChange;
  const checkboxColor: IconProps['color'] = hasWarnings
    ? 'error'
    : isReadyToCheck
    ? 'success'
    : 'inherit';

  return (
    <>
      <Checkbox
        {...checkboxProps}
        checked={isChecked}
        icon={<CheckBoxOutlineBlank color={checkboxColor} />}
        indeterminate={isLoading}
        indeterminateIcon={<CircularProgress size='1em' />}
        sx={{ fontSize: '1.5rem', ...checkboxProps.sx }}
        onChange={onChange}
      />
      {requiresConfirmation && (
        <Dialog open={confirmationDialog}>
          {isAllMatchCorrect ? (
            <DialogContent
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography variant='inherit' mb={2} textAlign='center'>
                {CONFIRMATION.NO_LATENCY}
              </Typography>

              <Stack direction='row'>
                <Checkbox
                  icon={<CheckBoxOutlineBlank color={checkboxColor} />}
                  checked={confirmCheckbox}
                  onChange={handleconfirmCheckbox}
                />
                <Typography variant='body2'>
                  {CONFIRMATION.CHECKBOX_SLA}
                </Typography>
              </Stack>
            </DialogContent>
          ) : (
            <DialogContent>{CONFIRMATION.GENERAL}</DialogContent>
          )}
          <DialogActions>
            <Button variant='text' onClick={closeConfirmationDialog}>
              {COMMON_STRING.CANCEL}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                closeConfirmationDialog();
                doChangeRef.current();
              }}
              disabled={!confirmCheckbox && isAllMatchCorrect}
            >
              {COMMON_STRING.CONFIRM}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
