import { Badge, Stack, Tooltip } from '@mui/material';

interface TableHeadCellWithBadgeProps {
  title: string;
  tooltipTitle: string;
  issueNumber: number;
}

export const TableHeadCellWithBadge = ({
  title,
  tooltipTitle,
  issueNumber,
}: TableHeadCellWithBadgeProps) => {
  return (
    <Stack alignItems='center' flexDirection='row' gap={0.5}>
      {title}
      {!!issueNumber && (
        <Tooltip arrow title={tooltipTitle} placement='top'>
          <Badge color='error' badgeContent={issueNumber} sx={{ right: -10 }} />
        </Tooltip>
      )}
    </Stack>
  );
};
