import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { COMMON_STRING } from '@/constants/dictionary';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import { ScoringContext } from '@/contexts/scoring/context';
import { useSlaBreachRules } from '@/service/hooks/useSlaBreachRules';
import {
  DeleteActionLatencyEvaluation,
  SCORING_WORKER_HOST_ACTION,
  SetActionLatencyEvaluationMsg,
} from '@/workers/scoring/types';
import { useSlaBreachActionEvaluation } from '@/service/hooks/useSlaBreachActionEvaluation';
import { LATENCY_EVALUATION_STATE_ID } from '@/service/constants';
import { MTDialog } from '../common/MTDialog';
import { ActionDialogProps } from '../ActionsDialog/types';
import { SemiBoldTypography } from '../common/Semiboldtypography';
import { DEFAULT_NOT_VERIFIED_LATENCY_ACTIONS } from '../ActionsTable/constants';
import { generateLatencyStatus } from '../ActionsTable/utils';
import {
  convertTimeTicks,
  generateEvaluationSubTitle,
  generateSubmitTimeticks,
  getSlaEvaluationSelect,
} from './utils';
import {
  LATENCY_EVALUATION_OPTION_NAMES,
  LATENCY_EVALUATION_STATE,
  LATENCY_OPTIONS,
  MISTAKE_RULE,
} from './constants';
import { EvaluationSelect } from './common/EvaluationSelect';
import { EvaluationCommentField } from './common/EvaluationCommentField';
import { LatencyCounter } from './common/LatencyCounter';
import { EvaluationButtons } from './common/EvaluationButtons';

export interface LatencyTime {
  minutes: number;
  seconds: number;
}

export const ActionEvaluationDialog = ({
  action,
  onClose,
}: ActionDialogProps) => {
  const {
    state: { fixtureId },
    fixtureConfigState: { fixtureConfig },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { dispatch: setLatencyEvaluation } =
    useDispatchWithResponse<SetActionLatencyEvaluationMsg>(
      SCORING_WORKER_HOST_ACTION.SET_ACTION_LATENCY_EVALUATION,
    );

  const { dispatch: deleteActionEvaluation } =
    useDispatchWithResponse<DeleteActionLatencyEvaluation>(
      SCORING_WORKER_HOST_ACTION.DELETE_LATENCY_EVALUATION,
    );

  const { mutate } = useSlaBreachActionEvaluation({
    fixtureId,
  });
  const { slaBreachRules } = useSlaBreachRules();

  const sortedRules = slaBreachRules?.sort((a, b) =>
    a.displayRuleName.localeCompare(b.displayRuleName),
  );

  const initialLatencySelect =
    DEFAULT_NOT_VERIFIED_LATENCY_ACTIONS.includes(action.fixtureActionTypeId) &&
    !action.slaBreachSummary
      ? LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED
      : generateLatencyStatus(action);

  const [latencyTime, setLatencyTime] = useState<LatencyTime>(
    convertTimeTicks(action?.slaBreachSummary?.latencyTicks),
  );
  const [slaRules, setSlaRules] = useState(sortedRules);
  const [comment, setComment] = useState('');
  const [latencyOption, setLatencyOption] = useState(initialLatencySelect);
  const [selectedMistake, setMistake] = useState(
    getSlaEvaluationSelect(action, slaRules),
  );

  useEffect(() => {
    setSlaRules(slaBreachRules);
  }, [slaBreachRules]);

  useEffect(() => {
    if (slaBreachRules && !!slaBreachRules.length) {
      const validItem = getSlaEvaluationSelect(action, slaRules);
      setMistake(validItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, slaBreachRules]);

  useEffect(() => {
    setComment(action.slaBreachSummary?.evaluationReasonNote || '');
  }, [action.slaBreachSummary?.evaluationReasonNote]);

  const onSubmit = async () => {
    await setLatencyEvaluation({
      fixtureId,
      id: action.id,
      evaluationRuleId: 9,
      latencyTicks: generateSubmitTimeticks(latencyOption, latencyTime),
      latencyEvaluationState: [
        LATENCY_EVALUATION_OPTION_NAMES.NO_DELAY,
        LATENCY_EVALUATION_OPTION_NAMES.DELAY,
      ].includes(latencyOption)
        ? LATENCY_EVALUATION_STATE_ID.DELAY
        : LATENCY_EVALUATION_STATE[latencyOption],
      actionTimestamp: action.timestamp,
      evaluationReasonNote: comment,
      evaluationReason: selectedMistake,
    })
      .then(() => mutate())
      .finally(() => onClose());
  };

  const onDelete = async () =>
    await deleteActionEvaluation({
      fixtureId: fixtureId,
      id: action.id,
    })
      .then(() => mutate())
      .finally(() => onClose());

  const onMistakeHandler = (event: SelectChangeEvent) => {
    const selectedRuleType = slaRules?.find(
      (item) => event.target.value === item.displayRuleName,
    )?.displayRuleName;
    if (selectedRuleType) {
      setMistake(selectedRuleType);
    } else {
      setMistake(MISTAKE_RULE.NONE);
    }
  };

  const onLatencySelect = (event: SelectChangeEvent) => {
    const selectedType = LATENCY_OPTIONS.find(
      (item) => event.target.value === item,
    );

    if (selectedType) {
      setLatencyOption(selectedType);
    } else {
      setLatencyOption(LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED);
    }
  };

  if (!slaBreachRules?.length) {
    return null;
  }

  return (
    <MTDialog
      aria-label={ARIA_LABEL.ACTION_EVALUATION_DIALOG}
      open={true}
      onClose={onClose}
      title={COMMON_STRING.ACTION_EVALUATION}
      maxWidth='xs'
      fullWidth
    >
      <Stack width='100%' gap={1}>
        <SemiBoldTypography>{COMMON_STRING.SPECIFY_LATENCY}</SemiBoldTypography>

        <Typography variant='subtitle2'>
          {generateEvaluationSubTitle({ action, fixtureConfig })}
        </Typography>
        <Select
          aria-label={ARIA_LABEL.LATENCY_SELECT_LABEL}
          value={latencyOption}
          onChange={onLatencySelect}
          size='small'
        >
          {LATENCY_OPTIONS.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {latencyOption === LATENCY_EVALUATION_OPTION_NAMES.DELAY && (
          <LatencyCounter
            latencyTime={latencyTime}
            changeLatency={setLatencyTime}
          />
        )}

        <SemiBoldTypography>{COMMON_STRING.MISTAKE}</SemiBoldTypography>
        <EvaluationSelect
          value={selectedMistake}
          onChange={onMistakeHandler}
          options={slaRules}
        />
        {selectedMistake !== MISTAKE_RULE.NONE && (
          <EvaluationCommentField addComment={setComment} comment={comment} />
        )}

        <EvaluationButtons
          onClose={onClose}
          onSubmit={onSubmit}
          onDelete={onDelete}
          disabledDelete={!action.slaBreachSummary}
        />
      </Stack>
    </MTDialog>
  );
};
