import { USER_ROLE_NAME } from '@/service/constants';
import { FixtureComparisonRating } from '@/service/types';

interface GetScorerNameProps {
  fixtureScorers: FixtureComparisonRating[];
  scorerId?: string;
  isSupervisorInclude?: boolean;
}

export const getScorerName = ({
  fixtureScorers,
  scorerId,
  isSupervisorInclude = false,
}: GetScorerNameProps) => {
  if (!fixtureScorers.length) {
    return;
  }

  if (!isSupervisorInclude) {
    const isSupervisor = fixtureScorers.some(
      (rating) =>
        rating.role.name === USER_ROLE_NAME.SUPERVISOR &&
        rating.user.oktaId === scorerId,
    );

    if (isSupervisor) {
      return;
    }
  }

  const scorer = fixtureScorers.find(
    (scorer) => scorer.user.oktaId === scorerId,
  );

  if (scorer) {
    return (
      `${scorer.user.firstname || ''} ${scorer.user.lastname || ''}`.trim() ||
      scorerId
    );
  } else {
    return scorerId;
  }
};
