import { DialogActions, Button, CircularProgress } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface EditButtonsProps {
  handleClose: VoidFunction;
  onSubmit: VoidFunction;
  disabled: boolean;
  isLoading: boolean;
  submitAndEvaluateAction?: VoidFunction;
}

export const EditButtons = ({
  handleClose,
  onSubmit,
  isLoading,
  submitAndEvaluateAction,
  disabled,
}: EditButtonsProps) => {
  return (
    <DialogActions>
      <Button variant='text' onClick={handleClose}>
        {COMMON_STRING.CANCEL}
      </Button>
      {submitAndEvaluateAction && (
        <Button
          disabled={disabled || isLoading}
          variant='outlined'
          startIcon={
            isLoading && <CircularProgress color='inherit' size='1em' />
          }
          onClick={submitAndEvaluateAction}
        >
          {COMMON_STRING.SAVE_AND_EVALUATE}
        </Button>
      )}
      <Button
        disabled={disabled || isLoading}
        variant='contained'
        startIcon={isLoading && <CircularProgress color='inherit' size='1em' />}
        onClick={onSubmit}
      >
        {COMMON_STRING.SAVE}
      </Button>
    </DialogActions>
  );
};
