import {
  Button,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import {
  COMMON_STRING,
  DELETE_CONFIRMATION_STRING,
} from '@/constants/dictionary';
import { ConfirmationButtonSet } from '@/components/common/ConfirmationButtonSet';

interface EvaluationButtonsProps {
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  onDelete: VoidFunction;
  disabledDelete: boolean;
}
export const EvaluationButtons = ({
  disabledDelete,
  onSubmit,
  onClose,
  onDelete,
}: EvaluationButtonsProps) => {
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false);
  const theme = useTheme();

  const handleCloseDeleteConfirmation = () =>
    setIsOpenDeleteConfirmation(false);

  return (
    <DialogActions sx={{ justifyContent: 'space-between' }}>
      <Tooltip
        placement='top'
        open={isOpenDeleteConfirmation}
        disableHoverListener
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[1],
            },
          },
        }}
        title={
          <ConfirmationButtonSet
            onConfirm={onDelete}
            onClose={handleCloseDeleteConfirmation}
            confirmMessage={DELETE_CONFIRMATION_STRING.LATENCY}
          />
        }
      >
        <IconButton
          sx={{ '&:hover': { color: theme.palette.error.main } }}
          aria-label='evaluation-delete'
          disabled={disabledDelete}
          onClick={() => setIsOpenDeleteConfirmation(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Stack gap={2} direction='row'>
        <Button onClick={onClose} variant='text'>
          {COMMON_STRING.CANCEL}
        </Button>
        <Button
          disabled={isOpenDeleteConfirmation}
          variant='contained'
          onClick={onSubmit}
        >
          {COMMON_STRING.SAVE}
        </Button>
      </Stack>
    </DialogActions>
  );
};
