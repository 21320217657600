import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography } from '@mui/material';
import { FixtureAction } from '@/service/types';
import { LATENCY_EVALUATION_STATE_ID } from '@/service/constants';
import {
  getReadableTime,
  convertTimeTicks,
} from '../ActionEvaluationDialog/utils';
import { AnimatedIconButton } from '../common/AnimatedIconButton';
import { BUTTON_NAME, DEFAULT_NOT_VERIFIED_LATENCY_ACTIONS } from './constants';
import { Format, generateLatencyStatus } from './utils';

export interface LatencyCellProps {
  action: FixtureAction;
  isSlaBreachActionSelected: boolean;
}

export const LatencyCell = ({
  action,
  isSlaBreachActionSelected,
}: LatencyCellProps) => {
  const isLatencyTicks = action.slaBreachSummary?.latencyTicks;
  const isNotVerifiedAction =
    DEFAULT_NOT_VERIFIED_LATENCY_ACTIONS.includes(action.fixtureActionTypeId) ||
    [
      LATENCY_EVALUATION_STATE_ID.NOT_VERIFIED,
      LATENCY_EVALUATION_STATE_ID.DELAY,
      LATENCY_EVALUATION_STATE_ID.TO_BE_CHECKED,
    ].includes(
      action.slaBreachSummary?.latencyEvaluationState ??
        (-1 as LATENCY_EVALUATION_STATE_ID),
    );

  if (!isLatencyTicks && !isNotVerifiedAction && !isSlaBreachActionSelected) {
    return null;
  }

  return (
    <AnimatedIconButton
      disabled={false}
      name={BUTTON_NAME.LATENCY}
      size='small'
      aria-label='latency-time-button'
      sx={{
        textDecoration: 'none',
        borderRadius:
          isLatencyTicks || isNotVerifiedAction
            ? (theme) => theme.spacing(0.5)
            : '50%',
      }}
    >
      {isLatencyTicks ? (
        getReadableTime(convertTimeTicks(action.slaBreachSummary?.latencyTicks))
      ) : isNotVerifiedAction ? (
        <Typography
          color={(theme) => theme.palette.text.primary}
          lineHeight={1.43}
          fontSize='0.875rem'
        >
          {generateLatencyStatus(action, Format.short)}
        </Typography>
      ) : (
        <AccessTimeIcon fontSize='small' />
      )}
    </AnimatedIconButton>
  );
};
