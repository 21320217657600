import { FixtureAction, FixtureConfig, SlaBreachRules } from '@/service/types';
import { formatDate } from '@/utils/formatDate/formatDate';
import { getActionName } from '../FixtureTabs/helpers/getActionName';
import { LatencyTime } from './ActionEvaluationDialog';
import { LATENCY_EVALUATION_OPTION_NAMES, MISTAKE_RULE } from './constants';

export const convertTimeTicks = (totalTicks?: number | null) => {
  if (!totalTicks || totalTicks === 0) return { minutes: 0, seconds: 0 };

  const totalSeconds = Math.floor(totalTicks / 10000000);
  const newMinutes = Math.floor(totalSeconds / 60);
  const newSeconds = totalSeconds % 60;
  return { minutes: newMinutes, seconds: newSeconds };
};

export const convertToTicks = ({ minutes, seconds }: LatencyTime) =>
  (minutes * 60 + seconds) * 10000000;

export const getSlaEvaluationSelect = (
  action: FixtureAction,
  slaBreachRules?: SlaBreachRules[],
) => {
  if (!slaBreachRules) {
    return MISTAKE_RULE.NONE;
  }

  if (!action.slaBreachSummary) {
    return (
      slaBreachRules.find((rule) => rule.displayRuleName === MISTAKE_RULE.NONE)
        ?.displayRuleName || MISTAKE_RULE.NONE
    );
  }

  const evaluationReason = action.slaBreachSummary.evaluationReason;
  const foundRule = slaBreachRules.find(
    (rule) => rule.displayRuleName === evaluationReason,
  );

  return foundRule ? foundRule.displayRuleName : MISTAKE_RULE.NONE;
};

export const getReadableTime = (time: LatencyTime) => {
  const minutes = time.minutes.toString().padStart(2, '0');
  const seconds = time.seconds.toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};

export const generateEvaluationSubTitle = ({
  action,
  fixtureConfig,
}: {
  action: FixtureAction;
  fixtureConfig?: FixtureConfig;
}) =>
  `${action.fixtureSeqNum}${` - ${getActionName(
    action.fixtureActionTypeId,
    fixtureConfig,
  )} -`} ${action.clockTimeString} (${formatDate(action.timestamp)})`;

export const generateSubmitTimeticks = (
  latencyOption: string,
  latencyTime: LatencyTime,
) => {
  if (latencyOption === LATENCY_EVALUATION_OPTION_NAMES.NO_DELAY) {
    return 0;
  } else if (
    (latencyOption === LATENCY_EVALUATION_OPTION_NAMES.DELAY &&
      latencyTime?.minutes === 0 &&
      latencyTime.seconds === 0) ||
    [
      LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED,
      LATENCY_EVALUATION_OPTION_NAMES.TO_BE_CHECKED,
    ].includes(latencyOption)
  ) {
    return null;
  } else {
    return convertToTicks(latencyTime);
  }
};
