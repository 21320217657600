import { COMMON_STRING } from '@/constants/dictionary';
import { LATENCY_EVALUATION_OPTION_NAMES } from '@/components/ActionEvaluationDialog/constants';
import { RadioLabelsType } from './types';

export const FILTERS_HISTORY_MAX_LENGTH = 10;

export const FILTER_PROPERTY = {
  ACTION_ID: 'actionId',
  SEND_TYPE_ID: 'sendTypeId',
  PERIOD: 'period',
  FIXTURE_ACTION_TYPE_ID: 'fixtureActionTypeId',
  TEAM_ID: 'teamId',
  PLAYER_ID: 'playerId',
  FLAG: 'flag',
  COMMENT: 'fixtureActionComments',
  SCORER_ID: 'scorerId',
  EVALUATOR_ID: 'slaBreachSummary.userId',
  EVALUATION_REASON: 'slaBreachSummary.evaluationReason',
  LATENCY_STATE: 'slaBreachSummary.latencyEvaluationState',
  LATENCY_TICKS: 'slaBreachSummary.latencyTicks',
} as const;

export const NON_PROPERTY_FILTER = {
  HIDE_UPDATED_ACTIONS: 'hideUpdatedActions',
} as const;

export const FILTER_DISPLAY_NAME = {
  ACTION_ID: 'ActionID',
  SEND_TYPE_ID: COMMON_STRING.SEND_TYPE,
  PERIOD: COMMON_STRING.PERIOD,
  FIXTURE_ACTION_TYPE_ID: COMMON_STRING.ACTION,
  TEAM_ID: COMMON_STRING.TEAM,
  PLAYER_ID: COMMON_STRING.PLAYER,
  FLAG: 'Flag',
  HIDE_FIXTURE_ACTION_TYPE_ID: 'Hide Action',
  COMMENT: COMMON_STRING.COMMENT,
  ACTIONS_UPDATED: 'Actions updated',
  LATENCY: COMMON_STRING.LATENCY,
  MISTAKE: COMMON_STRING.MISTAKE,
  SCORER: COMMON_STRING.SCORER,
  EVALUATOR: COMMON_STRING.EVALUATOR,
};

export const FILTER_DISPLAY_VALUE = {
  COMMENT: {
    WITH_COMMENT: 'With Comment',
    WITH_OUT_COMMENT: 'Without Comment',
  },
  FLAG: { FLAGGED: 'Flagged', NOT_FLAGGED: 'Not Flagged' },
  LATENCY: {
    DELAY: 'Delay',
    NO_DELAY: 'No Delay',
    NOT_VERIFIED: 'Not Verified',
    TO_BE_CHECKED: 'To Be Checked',
  },
  MISTAKE: {
    WITH_MISTAKE: 'With Mistakes',
    WITH_OUT_MISTAKE: 'Without Mistakes',
  },
};

export type FilterProperty =
  (typeof FILTER_PROPERTY)[keyof typeof FILTER_PROPERTY];

export const GENERIC_FILTER_VALUE = {
  UNSET: '',
  FALSY: 'falsy',
  TRUTHY: 'truthy',
  UNKNOWN: 'unknown',
  ASSIGNED: 'assigned',
} as const;
export type GenericFilterValue =
  (typeof GENERIC_FILTER_VALUE)[keyof typeof GENERIC_FILTER_VALUE];

export const FILER_TEMPLATE_COLUMN_QUANTITY = 3;

export const RADIO_FLAG_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.FLAG.NOT_FLAGGED,
  truthy: FILTER_DISPLAY_VALUE.FLAG.FLAGGED,
};

export const RADIO_COMMENT_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: FILTER_DISPLAY_VALUE.COMMENT.WITH_OUT_COMMENT,
  truthy: FILTER_DISPLAY_VALUE.COMMENT.WITH_COMMENT,
};

export const RADIO_PLAYER_LABELS: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  falsy: COMMON_STRING.NONE,
  unknown: COMMON_STRING.UNKNOWN,
};

export const RADIO_ACTIONS_UPDATED: RadioLabelsType = {
  falsy: COMMON_STRING.ALL,
  truthy: COMMON_STRING.ONLY_NOT_UPDATED,
};

export const RADIO_SLA_LATENCY: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.LATENCY.DELAY,
  falsy: FILTER_DISPLAY_VALUE.LATENCY.NO_DELAY,
  unknown: FILTER_DISPLAY_VALUE.LATENCY.NOT_VERIFIED,
  assigned: FILTER_DISPLAY_VALUE.LATENCY.TO_BE_CHECKED,
};

export const RADIO_SLA_MISTAKE: RadioLabelsType = {
  unset: COMMON_STRING.ALL,
  truthy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE,
  falsy: FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
};

export const RADIO_SCORER_LABELS: RadioLabelsType = {
  falsy: COMMON_STRING.ALL,
  unknown: COMMON_STRING.SUPERVISOR,
};

export const SLA_MISTAKE_FILTER = {
  unset: [],
  falsy: [
    {
      property: FILTER_PROPERTY.EVALUATION_REASON,
      value: COMMON_STRING.NONE,
      displayName: FILTER_DISPLAY_NAME.MISTAKE,
      displayValue: FILTER_DISPLAY_VALUE.MISTAKE.WITH_OUT_MISTAKE,
    },
  ],
  truthy: [
    {
      property: FILTER_PROPERTY.EVALUATION_REASON,
      value: COMMON_STRING.MISTAKE,
      displayName: FILTER_DISPLAY_NAME.MISTAKE,
      displayValue: FILTER_DISPLAY_VALUE.MISTAKE.WITH_MISTAKE,
    },
  ],
};

export const LATENCY_VALUE_MAP: Record<string, GenericFilterValue> = {
  [LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED]: GENERIC_FILTER_VALUE.UNKNOWN,
  [LATENCY_EVALUATION_OPTION_NAMES.DELAY]: GENERIC_FILTER_VALUE.TRUTHY,
  [LATENCY_EVALUATION_OPTION_NAMES.TO_BE_CHECKED]:
    GENERIC_FILTER_VALUE.ASSIGNED,
};

export const SLA_LATENCY_FILTER = {
  unset: [],
  falsy: [
    {
      property: FILTER_PROPERTY.LATENCY_STATE,
      value: LATENCY_EVALUATION_OPTION_NAMES.NO_DELAY,
      displayName: FILTER_DISPLAY_NAME.LATENCY,
      displayValue: FILTER_DISPLAY_VALUE.LATENCY.NO_DELAY,
      extraProperty: FILTER_PROPERTY.LATENCY_TICKS,
    },
  ],
  unknown: [
    {
      property: FILTER_PROPERTY.LATENCY_STATE,
      value: LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED,
      displayName: FILTER_DISPLAY_NAME.LATENCY,
      displayValue: FILTER_DISPLAY_VALUE.LATENCY.NOT_VERIFIED,
      extraProperty: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
    },
  ],
  truthy: [
    {
      property: FILTER_PROPERTY.LATENCY_STATE,
      value: LATENCY_EVALUATION_OPTION_NAMES.DELAY,
      displayName: FILTER_DISPLAY_NAME.LATENCY,
      displayValue: FILTER_DISPLAY_VALUE.LATENCY.DELAY,
      extraProperty: FILTER_PROPERTY.LATENCY_TICKS,
    },
  ],
  assigned: [
    {
      property: FILTER_PROPERTY.LATENCY_STATE,
      value: LATENCY_EVALUATION_OPTION_NAMES.TO_BE_CHECKED,
      displayName: FILTER_DISPLAY_NAME.LATENCY,
      displayValue: FILTER_DISPLAY_VALUE.LATENCY.TO_BE_CHECKED,
    },
  ],
};
