import { LATENCY_EVALUATION_STATE_ID } from '@/service/constants';

export const TEXT_FIELD_CONSTANCE = {
  CHARACTERS_LIMIT: 700,
  PLACEHOLDER: 'Describe other mistake...',
  CHARACTERS_REACHED: 'Characters limit reached',
};

export const MISTAKE_RULE = {
  NONE: 'None',
  ASSIGNED_TO_INCORRECT_TEAM: 'Assigned to Incorrect Team',
  ASSIGNED_TO_INCORRECT_PLAYER: 'Assigned to Incorrect Player',
  INCORRECT_WORKFLOW: 'Incorrect Workflow',
  MISSED_ACTION_DELETED: 'Missed (Action Deleted)',
  MISSED: 'Missed',
  EXCESSIVE: 'Excessive',
  OTHER: 'Other',
} as const;

export const MISTAKES_SHORTCUTS = {
  [MISTAKE_RULE.ASSIGNED_TO_INCORRECT_TEAM]: 'IT',
  [MISTAKE_RULE.ASSIGNED_TO_INCORRECT_PLAYER]: 'IP',
  [MISTAKE_RULE.INCORRECT_WORKFLOW]: 'IW',
  [MISTAKE_RULE.MISSED_ACTION_DELETED]: 'AD',
  [MISTAKE_RULE.MISSED]: 'M',
  [MISTAKE_RULE.EXCESSIVE]: 'E',
  [MISTAKE_RULE.OTHER]: 'O',
};

export type MistakeRule = (typeof MISTAKE_RULE)[keyof typeof MISTAKE_RULE];

export const allowedCommentKeys = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Control',
  'Meta',
];

export const LATENCY_EVALUATION_OPTION_NAMES = {
  NOT_VERIFIED: 'Not verified',
  DELAY: 'Delay',
  NO_DELAY: 'No delay',
  TO_BE_CHECKED: 'To be checked',
};

export const LATENCY_OPTIONS = Object.values(LATENCY_EVALUATION_OPTION_NAMES);

export const LATENCY_EVALUATION_STATE = {
  [LATENCY_EVALUATION_OPTION_NAMES.NOT_VERIFIED]:
    LATENCY_EVALUATION_STATE_ID.NOT_VERIFIED,
  [LATENCY_EVALUATION_OPTION_NAMES.DELAY]: LATENCY_EVALUATION_STATE_ID.DELAY,
  [LATENCY_EVALUATION_OPTION_NAMES.TO_BE_CHECKED]:
    LATENCY_EVALUATION_STATE_ID.TO_BE_CHECKED,
} as const;
