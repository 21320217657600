import { Stack, Chip, Tooltip, IconButton, alpha } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { AnyPrimitive } from '@/types/common';
import { FixtureConfig, FixtureSummary } from '@/service/types';
import { ExportToXlsxButton } from '@/components/common/ExportToXlsxButton';
import { PermissionsChecker } from '@/components/PermissionsChecker/PermissionsChecker';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { PreferencesContext } from '@/contexts/preferences/context';
import { ARIA_LABEL } from '@/constants/ariaLabel';
import {
  createActionsSheet,
  createFixtureSheet,
  createScorersheetTeamStats,
} from '../utils';
import type { ActionFilter } from '../ActionFilters/useActionsFilters';
import { ActionsTableContext } from '../context/ActionsTableContext';
import { TemplateConfirmationDialog } from '../ActionFilters/Template/TemplateConfirmationDialog';
import { FilterButtons } from './FilterButtons';

interface ActionTableBarProps {
  disableFilters: boolean;
  fixtureSummary: FixtureSummary | null;
  fixtureConfig?: FixtureConfig;
}

export const ActionsTableActionBar: FC<ActionTableBarProps> = ({
  disableFilters,
  fixtureConfig,
  fixtureSummary,
}) => {
  const {
    displayActions,
    filters,
    onFilterRemove,
    addFiltersToTemplate,
    resetTemplate,
    excludeTemplates,
  } = useContext(ActionsTableContext);
  const {
    selectedTemplateFilters,
    selectedTemplate,
    dontShowFilterTemplateApplyWarning,
    actions: { setDontShowFilterTemplateApplyWarning },
  } = useContext(PreferencesContext);
  const [
    addToTemplateConfirmationVisible,
    setAddToTemplateConfirmationVisible,
  ] = useState(false);

  const isBasketball = getSport(fixtureConfig)?.id === SPORT_ID.BASKETBALL;

  const getExportSheetData = useCallback(() => {
    const defaultSheets = [
      createActionsSheet(displayActions, fixtureSummary, fixtureConfig),
      createFixtureSheet(fixtureConfig),
    ];

    if (isBasketball) {
      return [
        ...defaultSheets,
        createScorersheetTeamStats({
          isHomeTeam: true,
          fixtureConfig,
          fixtureSummary,
        }),
        createScorersheetTeamStats({ fixtureConfig, fixtureSummary }),
      ];
    }
    return defaultSheets;
  }, [isBasketball, displayActions, fixtureSummary, fixtureConfig]);

  const shouldShowAddToTemplateButton = useMemo(() => {
    if (!selectedTemplate || excludeTemplates || !filters.length) {
      return false;
    }

    return (
      (filters.some((filter) => filter.isFromTemplate) &&
        filters.some((filter) => !filter.isFromTemplate)) ||
      filters.filter((filter) => filter.isFromTemplate).length !==
        selectedTemplateFilters?.length
    );
  }, [filters, selectedTemplateFilters, excludeTemplates, selectedTemplate]);

  const shouldShowResetTemplateButton = useMemo(() => {
    if (excludeTemplates) {
      return false;
    }

    if (!filters.length || !selectedTemplateFilters?.length) {
      return false;
    }

    return (
      selectedTemplateFilters?.length !==
      filters.filter((filter) => filter.isFromTemplate).length
    );
  }, [filters, selectedTemplateFilters, excludeTemplates]);

  const onAddToTemplate = () => {
    if (dontShowFilterTemplateApplyWarning) {
      addFiltersToTemplate();
    } else {
      setAddToTemplateConfirmationVisible(true);
    }
  };

  const createFilterChip = (
    columnName: string,
    value: AnyPrimitive,
    isFromTemplate: boolean | undefined,
    onDelete?: () => void,
  ) => {
    return (
      <Chip
        key={columnName + value}
        sx={{
          cursor: 'inherit',
          textTransform: 'capitalize',
        }}
        size='small'
        label={`${columnName}: ${value ?? 'none'}`}
        onDelete={onDelete}
        variant={isFromTemplate || !selectedTemplate ? 'filled' : 'outlined'}
        color={isFromTemplate || !selectedTemplate ? 'default' : 'primary'}
      />
    );
  };

  const getFiltersChips = (
    filters: ActionFilter[],
    onDelete?: (filterIndex: number) => void,
  ) =>
    filters.map(
      (
        { displayName, property, displayValue, value, exclude, isFromTemplate },
        index,
      ) => {
        const onFilterDelete = onDelete ? () => onDelete(index) : undefined;
        const name: string = `${exclude ? 'Hide ' : ''}${
          displayName || property
        }`;
        const val = displayValue || value;
        return createFilterChip(name, val, isFromTemplate, onFilterDelete);
      },
    );

  const addToTemplateComponent = (
    <Tooltip title={COMMON_STRING.FILTER_TEMPLATE_ADD_TOOLTIP} placement='top'>
      <IconButton
        aria-label={ARIA_LABEL.ADD_TO_TEMPLATE_BUTTON}
        size='small'
        sx={{
          color: (theme) => theme.palette.primary.main,
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          },
        }}
        onClick={onAddToTemplate}
      >
        <DoneIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Tooltip>
  );

  const resetTemplateComponent = (
    <Tooltip title={COMMON_STRING.RESET_TEMPLATE_TOOLTIP} placement='top'>
      <IconButton
        aria-label={ARIA_LABEL.RESET_TEMPLATE_BUTTON}
        size='small'
        sx={{
          color: (theme) => theme.palette.primary.main,
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          },
        }}
        onClick={resetTemplate}
      >
        <RestartAltIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Tooltip>
  );

  const sortFiltersByTemplate = () => {
    return filters.sort((a, b) =>
      a.isFromTemplate === b.isFromTemplate ? 0 : a.isFromTemplate ? -1 : 1,
    );
  };

  const onAddFiltersToTemplate = (dontShowAgain: boolean) => {
    if (dontShowAgain) {
      setDontShowFilterTemplateApplyWarning();
    }
    addFiltersToTemplate();
    setAddToTemplateConfirmationVisible(false);
  };

  return (
    <>
      <Stack direction='row' gap={1}>
        <Stack
          p={0.5}
          direction='row'
          gap={0.5}
          alignItems='center'
          flex={1}
          flexWrap='wrap'
        >
          {!!filters.length &&
            getFiltersChips(
              sortFiltersByTemplate(),
              disableFilters ? undefined : onFilterRemove,
            )}
          {shouldShowAddToTemplateButton && addToTemplateComponent}
          {shouldShowResetTemplateButton && resetTemplateComponent}
        </Stack>
        <Stack direction='row' maxWidth='25%' alignItems='flex-start'>
          <PermissionsChecker name='FixturePage.MatchData.Filters'>
            <FilterButtons disableFilters={disableFilters} />
          </PermissionsChecker>
          <PermissionsChecker name='FixturePage.MatchData.ExportXLSX'>
            <ExportToXlsxButton
              fileName={`${fixtureConfig?.fixture.name} report`}
              getData={getExportSheetData}
            />
          </PermissionsChecker>
        </Stack>
      </Stack>
      <TemplateConfirmationDialog
        showDialog={
          addToTemplateConfirmationVisible &&
          !dontShowFilterTemplateApplyWarning
        }
        onConfirm={onAddFiltersToTemplate}
        onCancel={() => setAddToTemplateConfirmationVisible(false)}
        title={COMMON_STRING.OVERRIDE_TEMPLATE}
        dialogText={COMMON_STRING.OVERRIDE_TEMPLATE_BODY}
      />
    </>
  );
};
