import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { GENERIC_FILTER_VALUE } from './constants';
import { GridWrap } from './Template/GridWrap';

export interface GenericFilterProps<Type> {
  value: Type;
  onValueChange: (value: Type) => void;
  labels: {
    unset?: string;
    falsy: string;
    truthy?: string;
    unknown?: string;
    assigned?: string;
  };
  row?: boolean;
  name?: string;
}
export const GenericFilterRadios = <Type,>({
  value,
  onValueChange,
  labels,
  row,
  name,
}: GenericFilterProps<Type>) => {
  const radios = (
    <>
      {labels.unset && (
        <FormControlLabel
          aria-label={`${name}-${GENERIC_FILTER_VALUE.UNSET}`}
          value={GENERIC_FILTER_VALUE.UNSET}
          label={labels.unset}
          control={<Radio size='small' />}
        />
      )}

      <FormControlLabel
        aria-label={`${name}-${GENERIC_FILTER_VALUE.FALSY}`}
        value={GENERIC_FILTER_VALUE.FALSY}
        label={labels.falsy}
        control={<Radio size='small' />}
      />

      {labels.unknown && (
        <FormControlLabel
          aria-label={`${name}-${GENERIC_FILTER_VALUE.UNKNOWN}`}
          value={GENERIC_FILTER_VALUE.UNKNOWN}
          label={labels.unknown}
          control={<Radio size='small' />}
        />
      )}

      {labels.truthy && (
        <FormControlLabel
          aria-label={`${name}-${GENERIC_FILTER_VALUE.TRUTHY}`}
          value={GENERIC_FILTER_VALUE.TRUTHY}
          label={labels.truthy}
          control={<Radio size='small' />}
        />
      )}

      {labels.assigned && (
        <FormControlLabel
          aria-label={`${name}-${GENERIC_FILTER_VALUE.ASSIGNED}`}
          value={GENERIC_FILTER_VALUE.ASSIGNED}
          label={labels.assigned}
          control={<Radio size='small' />}
        />
      )}
    </>
  );

  return (
    <RadioGroup
      value={value}
      onChange={({ target }) => onValueChange(target.value as Type)}
      row={row}
    >
      {row ? <GridWrap>{radios}</GridWrap> : radios}
    </RadioGroup>
  );
};
