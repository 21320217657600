import { Paper, Stack, Box, TableSortLabel } from '@mui/material';
import { useContext, MouseEvent, useMemo } from 'react';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { UIStateContext } from '@/contexts/UIState/context';
import { Order, SortBy } from '@/utils/sort/useSort';
import { COMMON_STRING, TABLE_SORT_ORDER } from '@/constants/dictionary';
import { TableHeadCellWithBadge } from '@/components/ActionsTable/TableHeadCellWithBadge';
import { ActionsTableContext } from '@/components/ActionsTable/context/ActionsTableContext';
import { ScoringContext } from '@/contexts/scoring/context';
import { hasPermissionsToElement } from '../PermissionsChecker/utils';
import {
  getColumnHeader,
  generateColumns,
  generateHeaderBadges,
} from './utils';

interface TableHeaderProps {
  sortOrder?: Order;
  sortBy?: SortBy;
  onRequestSort?: (event: MouseEvent<unknown>, property: SortBy) => void;
  isSoccer?: boolean;
}

const COLUMNS_FOR_SLA_TABLE = 3;
const COLUMNS_FOR_OTHER_TABLES = 0;
const COLUMNS_FOR_SOCCER = 2;

export const ActionsTableHeader = ({
  sortBy,
  sortOrder,
  onRequestSort,
  isSoccer = false,
}: TableHeaderProps) => {
  const {
    state: { deletedActionIds, fixtureActions },
  } = useContext(ScoringContext);
  const { displayActions } = useContext(ActionsTableContext);
  const { permissions } = useContext(RolePermissionsContext);
  const { isSlaBreachActionSelected } = useContext(UIStateContext);

  const badges = useMemo(
    () =>
      generateHeaderBadges({
        fixtureActions: fixtureActions?.actions,
        displayActions,
        deletedActionIds,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayActions],
  );

  const canAccessConfirmOrComment =
    hasPermissionsToElement('FixturePage.MatchData.ConfirmFlag', permissions) ||
    hasPermissionsToElement('FixturePage.MatchData.Comment', permissions);

  const createSortHandler =
    (property: SortBy) => (event: MouseEvent<unknown>) => {
      if (!onRequestSort) return;
      onRequestSort(event, property);
    };

  const columnsWithoutMore = Object.entries(
    getColumnHeader(isSlaBreachActionSelected, isSoccer).slice(0, -1),
  );

  return (
    <Paper
      elevation={2}
      sx={{
        top: 0,
        position: 'sticky',
        zIndex: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        direction='row'
        aria-label='actions-table-header'
        sx={{
          alignItems: 'center',
          '&>.MuiBox-root': {
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 1.25,
            padding: (theme) => theme.spacing(0.5, 0, 0.5, 1),
            '&:first-of-type': {
              paddingLeft: (theme) => theme.spacing(2.5),
            },
            '&:last-of-type': {
              padding: (theme) => theme.spacing(0.5, 0, 0.5, 0),
            },
          },
        }}
      >
        {columnsWithoutMore.map(([key, value], index) => (
          <Box
            width={value.width}
            key={key}
            textAlign={
              index >=
              columnsWithoutMore.length -
                (isSlaBreachActionSelected
                  ? COLUMNS_FOR_SLA_TABLE
                  : isSoccer
                  ? COLUMNS_FOR_SOCCER
                  : COLUMNS_FOR_OTHER_TABLES)
                ? 'center'
                : 'left'
            }
          >
            {index < columnsWithoutMore.length - 4 ? (
              <TableSortLabel
                active={!!sortBy?.primary && sortBy.primary === value.sortAttr}
                disabled={!value.sortAttr}
                direction={
                  value.sortAttr && sortBy?.primary === value.sortAttr
                    ? sortOrder
                    : TABLE_SORT_ORDER.ASC
                }
                onClick={createSortHandler({
                  primary: value.sortAttr,
                  secondary: value.secondarySortAttr,
                })}
              >
                {value.name}
              </TableSortLabel>
            ) : value.name === COMMON_STRING.PLAYER ? (
              <TableHeadCellWithBadge
                title={value.name}
                tooltipTitle={COMMON_STRING.NUMBER_OF_UNKNOWN_PLAYERS}
                issueNumber={badges.unknownPlayers.length}
              />
            ) : (
              value.name
            )}
          </Box>
        ))}

        {canAccessConfirmOrComment && (
          <Box width={generateColumns().MORE.width} textAlign='right'></Box>
        )}
      </Stack>
    </Paper>
  );
};
