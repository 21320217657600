import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SCORING_WORKER_HOST_ACTION,
  SetPostMatchCheckCompleteMsg,
  SetSlaBreachCompleteMsg,
} from '@/workers/scoring/types';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';
import { ERROR_MESSAGE } from './constants';
import { showError } from './utils';

export const useCheckboxHandlers = (fixtureId: string) => {
  const { useDispatchWithResponse } = useContext(ScoringContext);
  const { enqueueSnackbar } = useSnackbar();

  const setPostMatchCheck =
    useDispatchWithResponse<SetPostMatchCheckCompleteMsg>(
      SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_SET,
    );
  const unsetPostMatchCheck =
    useDispatchWithResponse<SetPostMatchCheckCompleteMsg>(
      SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_UNSET,
    );

  const setSlaComplete = useDispatchWithResponse<SetSlaBreachCompleteMsg>(
    SCORING_WORKER_HOST_ACTION.SLA_BREACH_COMPLETE_SET,
  );
  const unsetSlaComplete = useDispatchWithResponse<SetSlaBreachCompleteMsg>(
    SCORING_WORKER_HOST_ACTION.SLA_BREACH_COMPLETE_UNSET,
  );

  const beforePostMatchCheck = (isChecked: boolean) => {
    const dispatchAction = isChecked ? unsetPostMatchCheck : setPostMatchCheck;
    return dispatchAction.dispatch({ fixtureId });
  };
  const beforeSlaComplete = (isChecked: boolean) => {
    const dispatchAction = isChecked ? unsetSlaComplete : setSlaComplete;
    return dispatchAction.dispatch({
      fixtureId,
      timestamp: new Date().toISOString(),
    });
  };

  const beforeChangeHandlers: Record<
    number,
    (isChecked: boolean) => Promise<unknown>
  > = {
    [CHECKLIST_ELEMENT_INDEX.POST_MATCH_CHECK_COMPLETE]: beforePostMatchCheck,
    [CHECKLIST_ELEMENT_INDEX.ALL_MATCH_DATA_CORRECT]: beforeSlaComplete,
    [CHECKLIST_ELEMENT_INDEX.SLA_BREACH_COMPLETE]: beforeSlaComplete,
  };

  return {
    getBeforeChange: (checkboxIndex: number, requiresConfirmation: boolean) => {
      if (requiresConfirmation && beforeChangeHandlers[checkboxIndex]) {
        return beforeChangeHandlers[checkboxIndex];
      }
      return undefined;
    },

    getOnBeforeChangeError: (
      checkboxIndex: number,
      requiresConfirmation: boolean,
    ) => {
      if (requiresConfirmation && ERROR_MESSAGE[checkboxIndex]) {
        return showError(enqueueSnackbar, ERROR_MESSAGE[checkboxIndex]);
      }
      return undefined;
    },
  };
};
