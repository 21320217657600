import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { CHECKLIST_ELEMENT_INDEX } from '@/service/types/checklist';
import { ScoringContext } from '../scoring/context';
import { ChecklistContext } from './context';
import { checklistReducer, getChecklistDefaultState } from './reducer';
import { ChecklistContextValue, CHECKLIST_REDUCER_ACTION } from './types';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot?.invalidate();
  });
}

/**
 * Prepares all the data for the checklist.
 * Provides all necessary dispatch functions.
 */
export const ChecklistProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: {
      checklistActions,
      fixtureChecklist,
      fixtureSummary,
      fixtureActions,
    },
    fixtureConfigState: { fixtureConfig },
  } = useContext(ScoringContext);

  const [checkboxes, checkboxesDispatch] = useReducer(
    checklistReducer,
    getChecklistDefaultState(),
  );

  const sport = getSport(fixtureConfig);
  const isSoccer = sport && sport.id === SPORT_ID.SOCCER;

  useEffect(() => {
    if (!fixtureChecklist) return;

    checkboxesDispatch({
      action: CHECKLIST_REDUCER_ACTION.CHECKBOXES,
      payload: isSoccer
        ? fixtureChecklist
        : fixtureChecklist.filter(
            (item) =>
              item.index !== CHECKLIST_ELEMENT_INDEX.ALL_MATCH_DATA_CORRECT,
          ),
    });
  }, [fixtureChecklist, isSoccer]);

  useEffect(() => {
    if (
      fixtureChecklist &&
      checklistActions &&
      fixtureSummary &&
      fixtureConfig &&
      fixtureActions
    )
      checkboxesDispatch({
        action: CHECKLIST_REDUCER_ACTION.VALIDATE,
        payload: {
          actions: checklistActions,
          summary: fixtureSummary,
          config: fixtureConfig,
          fixtureActions: fixtureActions,
        },
      });
  }, [
    fixtureChecklist,
    checklistActions,
    fixtureSummary,
    fixtureConfig,
    fixtureActions,
  ]);

  const value: ChecklistContextValue = {
    checkboxes,
  };
  return (
    <ChecklistContext.Provider value={value}>
      {children}
    </ChecklistContext.Provider>
  );
};
