import { FC, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FixtureAction } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { CustomTooltip } from '../StatsCompareTable/StyledStatsComponents';
import { TABLE_CLASS_NAMES } from './constants';
export type ActionsTableCellDataset = {
  columnName?: string;
  label?: string;
  filterProperty?: keyof FixtureAction | string;
  filterValue?: any;
  filterValueType?: 'string' | 'number' | 'boolean';
  lineThrough?: boolean;
  capitalizedCell?: boolean;
};
export type ActionsTableCellProps = {
  sublabel?: string;
  latency?: number;
} & ActionsTableCellDataset;

export const ActionsTableCell: FC<
  PropsWithChildren<StackProps & ActionsTableCellProps>
> = ({
  children,
  label,
  lineThrough,
  sublabel,
  capitalizedCell,
  columnName,
  filterProperty,
  filterValue,
  filterValueType = 'string',
  latency,
  ...restProps
}) => {
  return (
    <Stack
      data-column-name={columnName}
      data-label={columnName && label}
      data-filter-property={filterProperty}
      data-filter-value={filterProperty && filterValue}
      data-filter-value-type={filterProperty && filterValueType}
      justifyContent='center'
      lineHeight={1.43}
      fontSize='0.875rem'
      padding={(theme) => theme.spacing(1, 0, 1, 1)}
      sx={{
        '&:first-of-type': {
          paddingLeft: (theme) => theme.spacing(2.5),
        },
        textDecoration: lineThrough ? 'line-through' : 'none',
        textTransform: !capitalizedCell ? 'none' : 'capitalize',
      }}
      className={TABLE_CLASS_NAMES.CELL}
      {...restProps}
    >
      {latency ? (
        <Stack direction='row'>
          {label}
          {
            <CustomTooltip title={`${COMMON_STRING.LATENCY} ${latency}s`}>
              <WarningAmberIcon
                sx={{
                  alignSelf: 'center',
                  color: (theme) => theme.palette.primary.main,
                  fontSize: '1em',
                }}
              />
            </CustomTooltip>
          }
        </Stack>
      ) : (
        label
      )}
      {sublabel && (
        <Typography
          component='p'
          variant='caption'
          sx={{
            lineHeight: 1.1,
          }}
        >
          {sublabel}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
